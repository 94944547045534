import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MinimalLayout from '../layout/Minimal';
import isBrowser from '../utils/isBrowser';
import { ThemeType } from '../types';

const Page = styled.div(
  ({ theme: { colors }, pageHeight = '100vh' }: { pageHeight?: string } & ThemeType) => `
  height: ${pageHeight};
  width: 100%
  min-height: 100vh;
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: ${colors.blue};
  color: ${colors.black};
`,
);

const ContentWrapper = styled.div(
  () => `
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`,
);

function useWindowHeight() {
  function getHeight() {
    return isBrowser() && `${window.innerHeight}px`;
  }

  const [windowHeight, setWindowHeight] = useState(getHeight);

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    function handleResize() {
      setWindowHeight(getHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowHeight;
}

export const CountdownPage: React.StatelessComponent<any> = () => {
  const pageHeight = useWindowHeight();

  return (
    <MinimalLayout>
      <Page pageHeight={pageHeight}>
        <ContentWrapper>
          <h2>Looks like this page doesn't exist</h2>
          <a href="/">Return Home</a>
        </ContentWrapper>
      </Page>
    </MinimalLayout>
  );
};

export default CountdownPage;
