import React, { StyleHTMLAttributes } from 'react';
import { ThemeProvider, CSSObject } from 'styled-components';
import GlobalStyle from '../styles/GlobalStyle';
import theme from '../styles/theme';
import Head from './components/Head';

const MinimalLayout: React.StatelessComponent<{ style?: CSSObject }> = ({ children, ...props }) => (
  <ThemeProvider theme={theme}>
    <div className="page-layout" {...props}>
      <GlobalStyle />
      <Head />
      {children}
    </div>
  </ThemeProvider>
);

export default MinimalLayout;
